.spinner-container {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.manage-projects-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    font-family: 'Arial', sans-serif;
    background-color: #fff;
    
  }
  
  .manage-projects-form {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

    
    padding: 40px;
    border-radius: 12px;
  
  }

  .header-create{
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
    border-radius: 15px;
  }
  
  .form-group label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px;
    display: block;
  }
  
  input,
  textarea,
  select {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 12px;
  }
  
  
  
  .add-skill-button,
  .add-task-button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: #007bff;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  }
  
  .add-skill-button:hover,
  .add-task-button:hover {
    text-decoration: underline;
  }
  
  
  .form-group-half {
    flex: 1;
  }
  
  .add-new-task,
  .add-new-milestone {
    color: #007bff;
    cursor: pointer;
    font-size: 16px;
    text-decoration: underline;
  }
  
  .create-project-button {
    width: 100%;
    background-color: #f5c748;
    color: white;
    font-size: 18px;
    padding: 14px;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .create-project-button:hover {
    background-color: #e5b238;
  }
  
  
  
  
  @media (max-width: 768px) {
    .row {
      flex-direction: column;
    }
  
    .form-group-half {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  
  /* Increase height for the Skills needed input */
  input[placeholder="Enter required skills"] {
    height: 80px;
    background-color:#f5f5f5;;
  }


  .form-group.row {
    position: relative; /* Make the row container relative */
}

.inline-labels {
    position: absolute;
    right: 0; /* Align to the right */
    top: 0;
    display: flex;
    align-items: center;
    gap: 15px; /* Spacing between Due date and Assign */
}

.form-group-full{
  width:100%;
}






/* Container for Task and Budget fields */
/* .form-grouptask-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: 8%;
}


.form-group-half {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}


.task-input {
  width: 150%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.budget-input-group {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}


.budget-input {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
} */

.form-grouptask-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-left: 8%;
  justify-content: space-evenly;
}

.form-group-row {
  display: flex;
  align-items: center; /* Center items vertically */
  width: 100%;
}

/* Task input group takes 75% of the width */
.task-input-group {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
}

/* Budget and Due Date groups each take 12.5% */
.budget-input-group,
.due-date-input-group {
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  margin-left: 1rem;
}

.due-date-input-group {
  margin-top: -0.75rem;
}

/* Adjust input styles */
.task-input,
.budget-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

/* Style for due date input */
.due-date-input {
  width: 100%;
}

/* If using Ant Design's DatePicker or similar, adjust as necessary */
.due-date-input .ant-picker {
  width: 100%;
}

/* Optional: Remove last margin-right for alignment */
.form-group-row > div:last-child {
  margin-right: 0;
}

@media (max-width: 768px) {
  .form-group-row {
    flex-direction: column;
  }
  .task-input-group,
  .budget-input-group,
  .due-date-input-group {
    flex: 0 0 100%;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}




/* Container for buttons (Add new milestone, Add new task) */
.taskbutton-row {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 10px;
  margin-bottom: 1rem;
}

.milestonebutton-row {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

/* Styling for buttons */
.add-task-button {
  background: none;
  color: #007bff;
  border: none;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.add-task-button:hover {
  text-decoration: underline;
}

/* Styling for the + icon */
.add-task-button::before {
  content: '+';
  font-size: 20px;
  margin-right: 5px;
  color: black;
}

.inline-milestone-labels {
  display: flex;
  align-items: center;
}

.due-date,
.assign-members {
  display: flex;
  align-items: center;
}

.assign-members {
  margin-top: 5px;
}

.list-of-skills {
  display: flex;
  align-items: center;
}

.list-of-skills-wrapper {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.skillName {
  display: inline-flex;
    align-items: center;
    width: fit-content;
    position: relative;
    /* Make it the positioning reference for the close button */
    background-color: #f1f1f1;
    padding: 5px 20px;
    border-radius: 5px;
    font-family: 'AvenirNextLTPro', sans-serif !important;
    font-size: 14px;
    color: #333;
    /* Add extra space on the right for the close button */
}

/* Styling for the close (×) button */
.added-skillName-tag-close-btn {
  position: absolute;
  top: -3px;
  right: -3px;

  background: #ff4d4d;
  border: none;
  color: white;
  font-size: 8px;
  margin-left: 8px;
  cursor: pointer;
  padding: 0;
  line-height: 1;
  width: 12px;
  height: 12px;
  border-radius: 8px;
}

.added-skillName-tag-close-btn:hover {
  color: white;
}

/* Container for the toolbar */
.toolbar-container {
  display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-left: 80px;
    margin-right: 80px;
    padding: 40px;
    padding-bottom: 20px;
}

/* Left part of the toolbar (back button, tabs, search) */
.toolbar-left {
  display: flex;
  align-items: center;
}

/* Back button styling */
.back-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-right: 20px;
  font-weight: bold;
}

.back-button span {
  margin-left: 5px;
}

/* Tabs container */
.tabs-container {
  display: flex;
  align-items: center;
  background-color: #faf7f1;
  border-radius: 20px;
  margin-right: 20px;
  padding: 15px 15px;
}

/* Each tab styling */
.tab-item {
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 30px;
  font-size: 14px;
  color: #333;
  transition: background-color 0.3s ease;
}

.active-tab {
  background-color: #fff;
  font-weight: bold;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.search-inputproject {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px 15px;
  border-radius: 15px;
}



.toolbar-right .create-project-button {
  background-color: #f2c94c;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s ease;
  height: 40px; 
  
}

.create-project-button:hover {
  background-color: #d3a731;
}

.toolbar-right{
  padding-bottom: 30px;
}



.details-panel {
  position: fixed;
  right: -500px; 
  top: 0;
  width: 400px; 
  height: 100vh; 
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: right 0.3s ease; 
  z-index: 1000;
  overflow-y: auto; 
  overflow-x: hidden; 
}

.details-content {
  padding-bottom: 30px; 
}



.details-panel.visible {
  right: 0; 
}

.details-header {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 20px; 
}
.details-header img {
  margin-right: 15px; 
  width: 50px;       
  height: 50px;
  object-fit: cover;
}



.back-button {
  margin-left: auto; 
  background-color: #f0f0f0;
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}



.details-header h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  margin: 0; 
}





.project-title-wrapper {
  display: flex;
  flex-direction: column;    
  width: 122px;               
  height: 64px;               
  margin-left: 861px;         
  margin-top: 125px;          
}

.project-title-label {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 19.36px;
  color: #000000;
  margin-bottom: 10px;       
}

.project-title-input {
  width: 100%;               
  padding: 10px;
  font-size: 16px;
  border: 1px solid #D9D9D9;
  border-radius: 15px;
  box-sizing: border-box;
}


.project-title-input.no-border {
  border: none;
  background-color: transparent; 
  padding: 0; 
}

.project-details-header {
  display: flex;
  justify-content: space-between; 
  align-items: center;             
  margin-bottom: 20px;             
}

.project-title-section, 
.project-owner-section {
  flex: 1;                         
  margin-right: 20px;              
}

.project-title-section h3,
.project-owner-section h3 {
  margin-bottom: 5px;              
  font-size: 16px;
  font-weight: 700;
}

.project-title-section p,
.project-owner-section p {
  font-size: 14px;
  color: #333;
}

 
.skill-rectangle-text {
  font-family: 'Inter', sans-serif;
  font-size: 12px; 
  font-weight: 400;
  line-height: 14.52px; 
  text-align: left;
  color: #000000; 
  margin: 0;
  padding: 0;
  display: inline-block;
  opacity: 1;
}



.skill-rectangle-container {
  background-color: #F8F2DB;
  ; 
  border: none;
  border-radius: 20px; 
  color: white; 
  padding: 8px 16px; 
  font-size: 14px; 
  cursor: pointer; 
  text-align: center; 
  display: inline-block; 
  margin-bottom: 20px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}
.milestone-container {
  display: flex;
  flex-direction: column; 
  margin-bottom: 20px;
  width: 100%;
}

.task-list {
  list-style-type: none; 
  padding: 0;
  margin: 0;
  display: flex; 
  flex-wrap: wrap; 
  align-items: center; 
}

.task-item {
  display: flex;
  align-items: center;  
  margin-right: 20px; 
  margin-bottom: 10px; 
}

input[type="checkbox"] {
  margin-right: 10px; 
  vertical-align: middle;
  margin-bottom: 2px
}

.task-text {
  font-size: 16px;
  color: #333;
  white-space: nowrap;
  display: inline-block;
  

}

.completed-task {
  text-decoration: line-through;
  color: #999;
}

.budget-text {
  margin-top: 10px;
  font-weight: bold;
}

.attachment-dropzone {
  width: 350px;
  height: 54px;
  gap: 0px;
  border-radius: 15px 15px 15px 15px;
  border: 1px solid #000000; 
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white; 
  margin-top: 20px; 
}

.drop-documents {
  display: none;
 
}

.bold-label {
  font-weight: bold; 
  font-size: 16px; 
  margin-bottom: 6px; 
}
