/* Profile Page */
.profile-page {
    padding: 20px;
}

.profile-page__back-button button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
}

.profile-page__back-button img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.profile-page__content {
    max-width: 1200px;
    margin: 0 auto;
}

/* Profile Card Header Layout */
.profile-card__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

/* User Info Section */
.profile-card__user-info {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 20px;
}

.profile-card__user-details {
    margin-left: 20px;
}

/* Contact Info Section */
.profile-card__contact-info {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0;
    padding: 0 20px;
}

/* Compensation Section */
.compensation-section {
    flex: 1;
    padding: 0 20px;
}

/* Divider styling */
.profile-card__divider {
    width: 1px;
    height: 150px;
    background-color: #D9D9D9;
    margin: 0;
    align-self: center;
}

/* Contact section layout */
.contact-section {
    flex: 1;
    text-align: left;
}

/* Profile Card Base Styles */
.profile-card {
    width: 1196px;
    height: 366px;
    background-color: #F8F2DB;
    border-radius: 15px 0px 0px 0px;
    border: 1px solid #D9D9D9;
    margin-bottom: 20px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.profile-card__about {
    margin-top: 30px;
    clear: both;
}

/* Skills Card Styles */
.skills-card {
    margin-bottom: 20px;
    background-color: #F8F2DB !important;
}

.skills-card__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

/* Experience Card Styles */
.experience-card {
    background-color: #F8F2DB !important;
}

.experience-card__content {
    margin-top: 20px;
}

.experience-item {
    margin-bottom: 24px;
}

.experience-item:last-child {
    margin-bottom: 0;
}

/* Antd Overrides */
.ant-card {
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.ant-tag {
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 14px;
    background-color: white !important;
}

.ant-typography {
    margin-bottom: 0 !important;
}

h3.ant-typography {
    margin-bottom: 4px !important;
}

h4.ant-typography {
    margin-bottom: 16px !important;
}

.ant-card-body {
    padding: 24px;
}

/* Additional spacing and alignment utilities */
.profile-card__user-info h3,
.contact-section h3,
.compensation-section h3 {
    margin-bottom: 8px !important;
}

.profile-card__user-info p,
.contact-section p,
.compensation-section p {
    margin-bottom: 4px !important;
}

/* Ensure proper text alignment in each section */
.profile-card__user-info {
    text-align: left;
}

.contact-section,
.compensation-section {
    text-align: left;
}

/* Ensure dividers are perfectly centered */
.profile-card__contact-info {
    position: relative;
    display: flex;
    align-items: flex-start;
}

/* Mobile responsiveness (optional) */
@media (max-width: 768px) {
    .profile-card__header {
        flex-direction: column;
        gap: 30px;
    }

    .profile-card__divider {
        width: 100%;
        height: 1px;
        margin: 20px 0;
    }

    .profile-card {
        height: auto;
        width: 100%;
    }
}